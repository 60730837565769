import * as React from 'react';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import { getCartState } from '@msdyn365-commerce/global-state';
import { AsyncResult } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import { Regular } from '../../eden/eden-light-rays';
// @ts-ignore
import { blue30, spacing24 } from '../../eden/eden-style-constants';
// @ts-ignore
import { Cart, Profile, Search, World } from '../../eden/eden-icons';
// @ts-ignore
import Modal from '../../eden/eden-content-modal';
// @ts-ignore
import { Primary } from '../../eden/eden-buttons';
// @ts-ignore
import Callout from '../../eden/eden-callout';
import { ICountryLanguageEntity, ILanguageNameEntity } from '../../actions/DataServiceEntities.g';
import {
    // DesktopMenuContainer,
    PadBox,
    OptionsContainer,
    InlineContainer,
    SelectLabel
    // @ts-ignore
} from './header.styled.tsx';
import { CountryOption } from '../../interfaces/country-option.interface';
import { LanguageOption } from '../../interfaces/language-option.interface';
import { setLocale } from '../../helpers/localization/locale.helper';
import { ILocale } from '../../interfaces/ILocale';
import LdsAccount from './components/lds-account';
import { IHeaderViewProps } from './lds-header';
// @ts-ignore
import { WorkforceHeader, MenuButton } from '@churchofjesuschrist/eden-workforce-header';
// @ts-ignore
import { SidePanels, Panel } from '@churchofjesuschrist/eden-side-panels';
import { useState } from 'react';
import { ILdsHeaderConfig } from './lds-header.data';
import { HeaderImages } from './components/HeaderImages';
import mockCountryRegions from '../lds-checkout-shipping-address-4/mocks/country-regions';
import { countryCodeList } from '../lds-country-picker/countryList';

interface IHeaderViewPropsExtended extends IHeaderViewProps {
    data: {
        countries: AsyncResult<ICountryLanguageEntity[]>;
        languages: AsyncResult<ILanguageNameEntity[]>;
        cart: AsyncResult<any>;
        accountInformation: AsyncResult<any>;
        storeSelectorStateManager: AsyncResult<any>;
    };
    imageData: ILdsHeaderConfig;
}

const headerView: React.FC<IHeaderViewPropsExtended> = props => {
    const { HeaderTag, HeaderContainer, Divider, cartIcon, resources } = props;
    const [shouldShowSearchBox, setShouldShowSearchBox] = React.useState(false);
    const [LocalizationModalActive, setLocalizationModalActive] = React.useState(false);
    const [country, setCountry] = React.useState<CountryOption | null>();
    const [language, setLanguage] = React.useState<LanguageOption | null>();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [cartState, setCartState] = useState();
    const [selectedCountry, setSelectedCountry] = useState('USA');
    const [selectedLanguage, setSelectedLanguage] = useState('EN');

    const getCurrentCountry = async () => {
        let country;
        const currentCart = await getCartState(props.context.actionContext).catch((err: any) => {
            console.log(err);
        });
        let userCountry: any;
        if (props.data.countries.result) {
            //@ts-ignore
            if (currentCart && currentCart.cart && currentCart.cart.ExtensionProperties) {
                //@ts-ignore
                userCountry = currentCart.cart.ExtensionProperties.filter((property: any) => {
                    return property.Key === 'Country';
                });
            }
            country = props.data.countries.result.filter(country => {
                if (userCountry && userCountry[0]) {
                    //@ts-ignore
                    return country.countryRegionId === userCountry[0].Value.StringValue;
                } else {
                    return;
                }
            });
        }

        if (country) {
            if (userCountry && userCountry[0]) {
                // console.log('Country: ', userCountry);
                setSelectedCountry(userCountry[0].Value.StringValue);
                //@ts-ignore
                setCountry({ value: userCountry[0].Value.StringValue, label: country[0].CountryName });
            }
            setCartState(cartState);
        }
        return currentCart;
    };
    const getCurrentLanguage = async () => {
        const currentLanguage =
            props.context.request.locale.length > 2 ? props.context.request.locale.split('-')[0] : props.context.request.locale;
        let language;
        if (props.data.languages.result) {
            language = props.data.languages.result.filter(language => {
                const langId = language.LanguageId!.split('-');

                return langId[0] === currentLanguage;
            });
        }
        if (language && language[0]) {
            // console.log('Lang: ', language);
            setSelectedLanguage(language[0].LanguageId || 'en');
            //@ts-ignore
            setLanguage({ value: language[0].LanguageId, label: language[0].languageName });
        }
        return currentLanguage;
    };
    React.useEffect(() => {
        void getCurrentCountry();
        void getCurrentLanguage();
    }, [props.data.languages.status, props.data.countries.status]);

    const countriesList = props?.data?.countries?.result;
    const languagesList = props?.data?.languages?.result;

    const countries: CountryOption[] = [];
    const languages: LanguageOption[] = [];

    countriesList?.map(country => {
        const option: CountryOption = { label: country.CountryName || '', value: country.countryRegionId || '', country: country };
        countries.push(option);
    });

    languagesList?.map(language => {
        const option: LanguageOption = { label: language.languageName || '', value: language.LanguageId || '', language: language };
        languages.push(option);
    });

    const onCountryChange = (option: any) => {
        const countryValue = countries.filter(country => {
            return country.value === option;
        });
        // window['digitalData'].push({
        //     page: {
        //         info: {
        //             selectedRegion: country[0].label
        //         }
        //     }
        // });
        setSelectedCountry(countryValue[0].value);
        setCountry(countryValue[0]);
    };

    const onLanguageChange = (option: any) => {
        const languageValue = languages.filter(lang => {
            return lang.value === option;
        });
        // window['digitalData'].push({
        //     page: {
        //         info: {
        //             selectedLanguage: languages[0].label
        //         }
        //     }
        // });
        setSelectedLanguage(languageValue[0].value);
        setLanguage(languageValue[0]);
    };

    //ADOBE ANALYTICS
    const requestUrl = props.context.request.url.requestUrl.pathname ? props.context.request.url.requestUrl.pathname.split('/') : [''];
    requestUrl.shift();

    const breadCrumbs = requestUrl;
    const { request } = props.context;
    let geoRegion: string;
    if (
        request &&
        request.telemetryData &&
        request.telemetryData.activeCart &&
        //@ts-ignore
        request.telemetryData.activeCart.ExtensionProperties
    ) {
        //@ts-ignore
        const userCountry = request.telemetryData.activeCart.ExtensionProperties.filter((property: any) => {
            return property.Key === 'Country';
        });
        //@ts-ignore
        // geoRegion = request.telemetryData.activeCart.ExtensionProperties[2].Value.StringValue;
        geoRegion = userCountry[0].Value.StringValue;
    } else {
        geoRegion = request.channel && request.channel.CountryRegionId ? request.channel.CountryRegionId : '';
    }
    let userLanguage: any;
    let userCountry: any;

    React.useEffect(() => {
        if (props && props.data && props.data.languages && props.data.languages.result) {
            const currentLang = props.context.request.locale;
            const lang = currentLang.split('-')[0];
            userLanguage = props.data.languages.result.filter((res: any) => {
                return res.LanguageId === lang;
            });
        }
        if (countriesList) {
            userCountry = mockCountryRegions.filter((country: any) => {
                // return country.countryRegionId === geoRegion;
                return country.CountryRegionId === geoRegion;
            });
        }
        const langAbreviation = [
            { English: 'eng' },
            { Spanish: 'spa' },
            { Portuguese: 'por' },
            { French: 'fra' },
            { Deutsch: 'deu' },
            { Italian: 'ita' },
            { Russian: 'rus' },
            { Japanese: 'jpn' },
            { Korean: 'kor' },
            { Chinese: 'zho' }
        ];
        // @ts-ignore
        if (window && window['digitalData']) {
            // @ts-ignore
            window['digitalData'].push({
                event: 'Page View',
                page: {
                    info: {
                        name: request.telemetryPageName, //String - friendly name for the current page, usually based on the URL
                        breadCrumbs: breadCrumbs, // Site breadcrumbs for the current page
                        language: userLanguage && userLanguage[0] ? langAbreviation[userLanguage[0].languageName] : '', // String - Product language, consistent with churchofjesuschrist.org URL params and should be in short form as displayed here ie 3 letter abbreviation (FRA, DEU, ESP)
                        geoRegion: userCountry && userCountry[0] ? userCountry[0].ShortName : geoRegion, // String - country selected by the user in site settings
                        //@ts-ignore
                        requestUrl: request.requestUrl ? request.requestUrl : '',
                        siteName: 'Online Store' // String - friendly unique name that identifies the current site, this will be provided in a Jira ticket
                    },
                    category: {
                        primary: request.urlTokens.categories ? request.urlTokens.categories : {},
                        type: request.telemetryPageName === '4xx error' ? '404' : request.urlTokens.pageType
                    }
                },
                user: {
                    profile: {
                        info: {
                            loginStatus: request.user.isAuthenticated ? 'signed in' : 'signed out'
                        }
                    }
                }
            });
        }
    }, []);

    const showSearchBox = () => {
        setShouldShowSearchBox(!shouldShowSearchBox);
    };
    const openLocalizationModal = () => setLocalizationModalActive(true);
    const closeLocalizationModal = () => setLocalizationModalActive(false);

    const setLocalization = () => {
        const countryCodes = countryCodeList.filter((countryCodeItem: any) => {
            return countryCodeItem.threeLetterCode === country?.value;
        });
        // const urlForCookie = props.context.request.url.requestUrl.origin;
        const urlForCookie = window.location.origin;
        let cookieUrl: string;
        if (
            props.config.baseURLCountry &&
            props.config.baseURLLanguage &&
            selectedCountry.toLocaleLowerCase() === props.config.baseURLCountry.toLocaleLowerCase() &&
            selectedLanguage.toLocaleLowerCase() === props.config.baseURLLanguage.toLocaleLowerCase()
        ) {
            cookieUrl = urlForCookie;
        } else {
            cookieUrl = `${urlForCookie}/${country?.value.toLocaleLowerCase() || 'usa'}/${language?.value || 'en'}`;
        }

        props.onContinue(cookieUrl, countryCodes[0].twoLetterCode);
        closeLocalizationModal();
        const locale: ILocale = {
            country: country?.value.toLocaleLowerCase() || '',
            language: language?.value || ''
        };
        if (
            props.config.baseURLCountry &&
            props.config.baseURLLanguage &&
            selectedCountry.toLocaleLowerCase() === props.config.baseURLCountry.toLocaleLowerCase() &&
            selectedLanguage.toLocaleLowerCase() === props.config.baseURLLanguage.toLocaleLowerCase()
        ) {
            window.location.assign(urlForCookie);
        } else {
            setLocale(locale, props.context.actionContext.requestContext);
        }
    };

    const MobileMenu = (menuItems: any) => {
        console.log({ blah: menuItems });
        return (
            <SidePanels
                startPanel={
                    <Panel open={true} onClose={() => setShowMobileMenu(false)} preferredMode='dismissible'>
                        <div>
                            {menuItems.menuItems.map((slot: React.ReactNode, index: number) => {
                                return <div key={index}>{slot}</div>;
                            })}
                        </div>
                    </Panel>
                }
            />
        );
    };

    function _renderMobileMenuBar(items: React.ReactNode[]): JSX.Element | null {
        const barStyle: React.CSSProperties = {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
        };
        const iconContainerStyle: React.CSSProperties = {
            lineHeight: '60px',
            paddingRight: '16px',
            height: '60px',
            cursor: 'pointer'
        };
        const iconContainerStyleGlobe: React.CSSProperties = {
            lineHeight: '60px',
            paddingRight: '16px',
            paddingLeft: '16px',
            height: '60px',
            cursor: 'pointer'
        };
        const iconStyle: React.CSSProperties = {
            height: '60px',
            verticalAlign: 'middle'
        };
        const tools = (
            <div className='flex'>
                <span style={iconContainerStyle}>
                    <Search size='1.27rem' style={iconStyle} class='header-icon' onClick={() => showSearchBox()} />
                </span>
                <span style={iconContainerStyle}>{cartIcon}</span>

                <Node {...Divider} />
                <span style={iconContainerStyleGlobe}>
                    <World size='1.27rem' style={iconStyle} class='header-icon' onClick={openLocalizationModal} />
                </span>
                <span style={iconContainerStyle}>
                    <LdsAccount isSignedIn={props.isSignedIn} user={props.user} accountLinks={props.accountLinks} resources={resources} />
                </span>
            </div>
        );

        return (
            <div className='lds-mobile-nav-header'>
                <WorkforceHeader
                    style={barStyle}
                    menuButton={<MenuButton aria-label='My Menu' onClick={() => setShowMobileMenu(true)} />}
                    tools={tools}
                />
                {showMobileMenu && <MobileMenu menuItems={items} />}
            </div>
        );
    }

    function _renderDesktopMenuBar(items: React.ReactNode[]): JSX.Element | null {
        const iconContainerStyle: React.CSSProperties = {
            lineHeight: '60px',
            height: '60px',
            cursor: 'pointer'
        };
        const iconContainerStyleAccount: React.CSSProperties = {
            lineHeight: '60px',
            paddingRight: '16px',
            paddingLeft: '16px',
            height: '60px',
            cursor: 'pointer'
        };
        const iconStyle: React.CSSProperties = {
            height: '60px',
            verticalAlign: 'middle'
        };

        const DesktopMenuContainer: React.CSSProperties = {
            display: 'flex',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
        };
        return (
            <>
                <div style={DesktopMenuContainer} className='lds-nav-header'>
                    <Regular color={blue30} origin='start' style={{ height: 60 }} />
                    <div style={{ margin: 'auto' }}>
                        {items.map((slot: React.ReactNode, index: number) => {
                            return <React.Fragment key={index}>{slot}</React.Fragment>;
                        })}
                    </div>
                    <div className='flex'>
                        <span style={iconContainerStyle}>
                            <Search size='1.27rem' style={iconStyle} class='header-icon' onClick={() => showSearchBox()} />
                        </span>
                        {/* <span style={iconContainerStyle}>
                            <Cart size='1.27rem' style={iconStyle} class='header-icon' />
                        </span> */}
                        <span style={iconContainerStyle}>{cartIcon}</span>

                        <Node {...Divider} />
                        <span style={iconContainerStyle}>
                            <World size='1.27rem' style={iconStyle} class='header-icon' onClick={openLocalizationModal} />
                        </span>
                        <span style={iconContainerStyleAccount}>
                            <LdsAccount
                                isSignedIn={props.isSignedIn}
                                user={props.user}
                                accountLinks={props.accountLinks}
                                resources={props.resources}
                            />
                        </span>
                        {/* {_renderDesktopAccountBlock(props)} */}
                    </div>
                </div>
            </>
        );
    }

    function _renderSearchReactFragment(items: React.ReactNode[]): JSX.Element | null {
        return (
            <>
                {items && items.length > 0
                    ? items.map((slot: React.ReactNode, index: number) => {
                          return <React.Fragment key={index}>{slot}</React.Fragment>;
                      })
                    : null}
            </>
        );
    }

    // console.log({ headerProps: props });
    let sortedRegions: any;
    if (countries) {
        sortedRegions = countries.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    }
    let sortedLanguages: any;
    if (languages) {
        sortedLanguages = languages.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    }

    return (
        <Module {...HeaderTag}>
            <Modal
                active={LocalizationModalActive}
                onCloseGesture={closeLocalizationModal}
                heading={props.resources.languageSelectorHeader}
                className={'visible-overflow-modal'}
            >
                <PadBox>
                    <p>
                        {resources.countryLanguageDescriptionOne} {resources.countryLanguageDescriptionTwo}
                    </p>
                    <OptionsContainer>
                        <InlineContainer>
                            <label style={{ width: '100%' }}>
                                <SelectLabel>{resources.countrySelectLabel}</SelectLabel>
                                <select
                                    placeholder={resources.countrySelectPlaceholder}
                                    onChange={(e: any) => onCountryChange(e.target.value)}
                                    style={{ width: '100%', height: '38px' }}
                                >
                                    <option value='' disabled></option>
                                    {sortedRegions
                                        ? sortedRegions.map((region: CountryOption, index: any) => {
                                              return (
                                                  <option
                                                      value={region.value}
                                                      key={index}
                                                      selected={country && country.value === region.value ? true : false}
                                                  >
                                                      {region.label}
                                                  </option>
                                              );
                                          })
                                        : countries.map((region: CountryOption, index: any) => {
                                              return (
                                                  <option
                                                      value={region.value}
                                                      key={index}
                                                      selected={country && country.value === region.value ? true : false}
                                                  >
                                                      {region.label}
                                                  </option>
                                              );
                                          })}
                                </select>
                            </label>
                        </InlineContainer>
                        <InlineContainer>
                            <label style={{ width: '100%' }}>
                                <SelectLabel>{resources.languageSelectLabel}</SelectLabel>
                                <select
                                    placeholder={resources.languageSelectPlaceholder}
                                    onChange={(e: any) => onLanguageChange(e.target.value)}
                                    style={{ width: '100%', height: '38px' }}
                                >
                                    <option value='' disabled></option>
                                    {sortedLanguages
                                        ? sortedLanguages.map((lang: LanguageOption, index: any) => {
                                              return (
                                                  <option
                                                      value={lang.value}
                                                      key={index}
                                                      selected={language && language.value === lang.value ? true : false}
                                                  >
                                                      {lang.label}
                                                  </option>
                                              );
                                          })
                                        : languages.map((lang: LanguageOption, index: any) => {
                                              return (
                                                  <option
                                                      value={lang.value}
                                                      key={index}
                                                      selected={language && language.value === lang.value ? true : false}
                                                  >
                                                      {lang.label}
                                                  </option>
                                              );
                                          })}
                                </select>
                            </label>
                        </InlineContainer>
                    </OptionsContainer>
                    <p>
                        {resources.countryLanguageDescriptionThree}{' '}
                        <a href={props.config.globalServiceCenterLink?.linkUrl.destinationUrl || ''}>
                            {props.config.globalServiceCenterLink?.linkText}
                        </a>{' '}
                        {resources.countryLanguageDescriptionFour}{' '}
                        <a href={props.config.distributionServiceLink?.linkUrl.destinationUrl || ''}>
                            {props.config.distributionServiceLink?.linkText}
                        </a>
                        {resources.countryLanguageDescriptionFive ? resources.countryLanguageDescriptionFive : ''}
                    </p>
                    <Primary onClick={setLocalization}>{resources.countryLanguageContinueButtonText}</Primary>
                </PadBox>
            </Modal>
            <Node {...HeaderContainer}>
                <HeaderImages
                    storeImage={props.storeImage}
                    churchImage={props.churchImage}
                    churchLink={props.churchLink}
                    storeLink={props.storeLink}
                    context={props.context}
                />
                {_renderDesktopMenuBar(props.menuBar)}
                {_renderMobileMenuBar(props.menuBar)}
                {shouldShowSearchBox && _renderSearchReactFragment(props.search)}
            </Node>
        </Module>
    );
};

export default headerView;
